<template>
  <div :class="{ 'mt-4': margin }">
    <label 
      :id="name"
      v-if="label"
      class="block text-xs font-bold text-gray-700 antialiased"
    >
      {{ label }}
    </label>
    <div class="relative" :class="{'mt-1': label }">
      <button 
        type="button" 
        @click="click()"
        :aria-expanded="name"
        :aria-haspopup="name"
        :aria-labelledby="name+'-label'"
        class="mt-1 relative w-full border bg-gray-50 border-gray-300 focus:ring-primary focus:border-primary rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 sm:text-sm"
      >
        <span class="block truncate">{{ value ? value : empty }}</span>
        <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
          <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
          </svg>
        </span>
      </button>
      <transition
        enter-active-class="transition ease-in duration-50 transform"
        enter-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="transition ease-in duration-50 transform"
        leave-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div v-if="visible" class="z-10 fixed inset-0">
          <div @click="visible = false" class="absolute inset-0 bg-black opacity-10" tabindex="0"></div>
        </div>
      </transition>
      <transition
        enter-active-class="transition ease-in duration-50 transform"
        enter-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="transition ease-in duration-50 transform"
        leave-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ul v-if="visible" class="absolute mt-1 w-full z-10 bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm" tabindex="-1" :role="name" :aria-labelledby="name+'-label'" :aria-activedescendant="name+'-option-'+value">
          <li
            :key="key" 
            :id="name+'-'+key"
            @mouseleave="over = ''"
            @mouseover="over = item"
            v-for="(item, key) in values"
            @click="$emit('input', (item === value) ? '' : item), $emit('change'), visible = false"
            class="cursor-default select-none relative py-2 pl-3 pr-9" 
            :class="over === item ? 'text-white bg-primary' : 'text-gray-900'"
          >
            <span
              class="block truncate"
              :class="item === value ? 'font-semibold' : 'font-normal'"
            >
              {{ item }}
            </span>
            <span 
              v-if="item === value"
              class="absolute inset-y-0 right-0 flex items-center pr-4"
              :class="over === item ? 'text-white' : 'text-primary'"
            >
              <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
              </svg>
            </span>
          </li>
        </ul>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      over: '',
      visible: false,
    }
  },
  props: {
    label: { type: String, default: '' },
    name: { type: String, required: true },
    value: { type: String, required: true },
    values: { type: Array, required: true },
    margin: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    empty: { type: String, default: 'Selecione' },
  },
  mounted() {
    this.over = this.value
  },
  methods: {
    click() { 
      if(!this.disabled) {
        this.visible = !this.visible
        this.over = this.value
      }
    }
  }
}
</script>